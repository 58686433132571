form {
    .form-control {
        border-radius: 0;
        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 0;
        border-bottom-width: 1px;
        padding: 0.75rem 0;
        font-size: $font-size-base * 1.125;
    }
    input[type="file"].form-control {
        padding: 0.75rem 1.25rem;
    }
    .form-label {
        text-transform: uppercase;
        font-size: $font-size-base * 0.8;
        padding-bottom: 0.15rem;
        color: lighten($grey-mid, 15%);
    }

    div[data-field-type="checkboxes"] {
        legend.form-label {
            margin-bottom: 0.75rem;
        }
    }

    .form-check {
        display: flex;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        align-items: center;
        gap: 0.25rem;
        padding-left: 0;

        input.form-check-input {
            float: none;
            margin-left: 0;
            margin-top: 0;
            border: 1px solid darken($light, 25%);
            border-radius: 3px !important;
            width: 20px;
            height: 20px;
            padding: 0;
            background-color: hsl(0deg, 0%, 94%);

            &:hover {
                border-color: darken($light, 50%);
            }
            &:active {
                border-color: $accent;
            }
            &:checked {
                background-color: $accent;
                border-color: $accent;
            }
        }
        label.form-check-label {
            margin-left: 0.5rem;
        }
    }

    select {
        border-radius: 10px;
        padding: 0.5rem;
    }

    div[data-field-type="radio-buttons"] {
        fieldset {
            div {
                display: flex;
                gap: 1rem;
            }
        }

        // Radio Buttons Overrides
        input[type="radio"] {
            display: none;
        }
        input[type="radio"] + label {
            padding: 0.25rem 0.75rem;
            border: 1px solid lighten($grey, 40%);
            border-radius: 0.5rem;
            display: inline-block;
            margin: 0.15rem 0;
            background-color: transparent;

            &:hover {
                background-color: $black;
                border-color: $black;
                color: $white;
                cursor: pointer;
            }
        }
        input[type="radio"]:checked + label {
            background: #20E39D;
            border-color: #20CF90;

            box-shadow: 0px 32px 32px rgba(17, 106, 74, 0.08), 0px 16px 16px rgba(17, 106, 74, 0.08), 0px 4px 4px rgba(17, 106, 74, 0.08), 0px 2px 2px rgba(17, 106, 74, 0.08);

            &:hover {
                color: $black;
            }
        }
    }

    textarea.form-control {
        min-height: 8rem;
    }
}

// form e-mail field validation
input[type="email"] {
    //position: relative;
    //background-color: magenta !important;

    &.field-valid {
        //background-color: green !important;
    }
}

// formie overrides
.filepond--root.form-control {
    border: 0 !important;
}
.filepond--panel-root,
.filepond--item-panel {
    border-radius: 0;
}
.fui-alert {
    padding: 0 !important;
    margin: 2rem 0 !important;
    font-size: 1.125rem;

    &.message-error {
        color: $danger;
    }
}
.form-message.message-error,
.form-message.message-success {
    font-size: 1.125rem;
    border-radius: 0;
    margin-top: 2rem;
}

// switch styling
.form-switch .form-check-input {
    width: 2.25em;
    padding: 0.6rem;
    cursor: pointer;

    &:checked {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='black'></svg>");
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='var(--bs-green)'/%3e%3c/svg%3e");
    }
}
