//Fallback Google Fonts
//@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@700&family=Roboto:wght@300;400;700&display=swap');

@font-face {
    font-family: 'GT America';
    src: url('../../fonts/gt-america-regular.ttf');
    src: local('GT America Regular'), local('GTAmerica-Regular'),
         url('../../fonts/gt-america-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'GT America';
    src: url('../../fonts/gt-america-bold.ttf');
    src: local('GT America Bold'), local('GTAmerica-Bold'),
         url('../../fonts/gt-america-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'GT Alpina';
    src: url('../../fonts/gt-alpina-bold.otf');
    src: local('GT Alpina'), local('GT-Alpina'),
         url('../../fonts/gt-alpina-bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
