#searchInput {
    border: 0;
    border-bottom: 3px solid lighten($grey-mid, 20%);

    &:hover {
        border-color: $grey-mid;
    }

    &:focus {
        border-color: $dark;
    }
}

.filter-block,
.filter-layer {
    input[type='checkbox'] {
        width: 20px;
        height: 20px;
        margin: 0;

        &:not(:checked) {
            background-color: $light;
        }
    }
}

[data-filterblock='filterJahr'],
[data-filterlayer='filterJahr'] {
    span {
        border: 1px solid darken($light, 10%);
        background-color: $light;
        border-radius: 10px;
    }

    input {
        z-index: -1;

        &:checked + span {
            background-color: $accent;
            border-color: darken($accent, 5%);
        }
    }
}

.filter-layer {
    opacity: 0;
    pointer-events: none;
    transform: translateX(100%);
    transition: all .25s cubic-bezier(0.694, 0.048, 0.335, 1);

    &.open {
        opacity: 1;
        pointer-events: auto;
        transform: translateX(0%);

        &::-webkit-scrollbar {
            width: 5px;
        }
    }
}

// publication filters
.publication-filters,
.team-filters,
.news-filters,
.job-filters {
    .btn {
        &[aria-expanded='true'] {
            background-color: #dbdbdb !important;

            //caret icon
            svg {
                transform: rotate(180deg);
            }
        }
    }

    .nav-item {
        button {
            &[aria-selected='false'] {
                color: lighten($grey-mid, 15%);
            }
            &[aria-selected='true'] {
                text-decoration: underline;
            }
        }
    }
}

#angebotCollapse {
    h3 {
        &[aria-expanded='false'] {
            color: lighten($grey-mid, 15%);
        }
        &[aria-expanded='true'] {
            text-decoration: underline;
        }
    }
}
