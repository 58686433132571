.component-stage {
    .typewriter-text-wrapper {
        .height-container {
            height: 160px;

            @include media-breakpoint-up(md) {
                height: 220px;
            }

            @include media-breakpoint-up(lg) {
                height: 650px;
            }
        }

        .display-1 {
            min-height: 8rem;

            @include media-breakpoint-up(lg) {
                min-height: 18rem;
            }
        }
    }
    .stage-video {
        transform: scale(1.25) translateY(10%);

        @include media-breakpoint-down(lg) {
            transform: none;
        }
    }
}
