// Fontsize Fixes & Overrides (what's not possible with Bootstrap)

body {
    -webkit-font-smoothing: antialiased; //safari, chrome
    -moz-osx-font-smoothing: auto; //firefox
}

// adjust line-height and letter-spacing
main {
    h1, .h1 {
        line-height: 1.14;
        max-width: 980px;
        //letter-spacing: -1px;
    }
    h2, .h2 {
        line-height: 1.16;
        max-width: 980px;
        //letter-spacing: -1px;
    }
    h3, .h3 {
        line-height: 1.2;
        max-width: 980px;
        //letter-spacing: -0.5px;
    }
    h4, .h4 {
        line-height: 1.25;
        max-width: 980px;
        //letter-spacing: -0.25px;
    }
    h5, .h5 {
        line-height: 1.33;
        max-width: 980px;
    }
    h6, .h6 {
        line-height: 1.5;
        max-width: 980px;
        font-family: $font-family-base;
    }

    p {
        line-height: 1.44;
    }
}

p {
    margin-bottom: 0; //reset bootstrap default reboot margin-bottom on p tags
    max-width: 980px;
}

.font-family-serif {
    font-family: $headings-font-family;
}
.font-family-sans-serif {
    font-family: $font-family-base;
}

//body text
.content-text,
.component-text {
    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0.5em;
        margin-top: 0.75em;
    }
    p {
        font-size: $font-size-base * 1.125;
        margin-bottom: $spacer * 4;
        max-width: 780px;

        &:last-child {
            margin-bottom: 0;
        }
    }
    span, strong {
        font-size: $font-size-base * 1.125;
    }
    ul {
        max-width: 780px;
        font-size: $font-size-base * 1.125;
        margin-bottom: $spacer * 4;
        
        li {
            font-size: $font-size-base * 1.125;
            max-width: 780px;
        }
    }
    figure.table {
        font-size: $font-size-base * 1.125;        
    }
}

// image-text component
.component-image-text {
    .text-block {
        h1, h2, h3, h4, h5, h6 {
            margin-top: 0;
        }
    }
}

footer {
    .social-link {
        color: rgba(255, 255, 255, 0.8);
        transition: color .2s ease-out;

        &:hover {
            color: rgba(255, 255, 255, 1);
        }
    }
}

// all links user select none
a {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

// link "badge" hover style
a.border-gray.rounded-pill {
    transition: color .15s ease-in-out, border-color .15s ease-in-out;
    &:hover {
        color: $black !important;
        border-color: $black !important;
    }
}

.component-inpage-navigation {
    a {
        color: #666666;

        &:hover,
        &:focus,
        &:active,
        &.active-scrollelement {
            color: $black;
        }
    }
}
