@use 'sass:map';

// Variables
@use './utils/svg';

// bootstrap shade-color function
@function shade-color($color, $weight) {
    @return mix(black, $color, $weight);
}

// Color system
$dark:      hsl(0, 0, 13);
$light:     hsl(0, 0, 94);
$success:   hsl(153, 69, 31);
$info:      hsl(204, 97, 64);
$warning:   hsl(27, 91, 45);
$danger:    hsl(354, 70, 54);
$black:     hsl(0, 0, 0);
$white:     hsl(0, 0, 100);

// Custom Brand Colors
$green:        hsl(162, 100, 11);
$accent:       hsl(158, 78, 51);
$grey:         hsl(0, 0, 40);
$grey-mid:     hsl(0, 0, 27);

:root {
    --success:   #{$success};
    --info:      #{$info};
    --warning:   #{$warning};
    --danger:    #{$danger};
    --light:     #{$light};
    --dark:      #{$dark};
    --black:     #{$black};
    --white:     #{$white};
    --green:     #{$green};
    --accent:    #{$accent};
    --grey:      #{$grey};
    --grey-mid:  #{$grey-mid};
}

$theme-colors: (
    'success':   $success,
    'info':      $info,
    'warning':   $warning,
    'danger':    $danger,
    'light':     $light,
    'dark':      $dark,
    'black':     $black,
    'white':     $white,
);

// Enable / Disable Dark Mode
//$enable-dark-mode: false;

// Auto calculation contrast ratio setting
$min-contrast-ratio:   4.5;

// Add custom colors to theme-colors
$custom-theme-colors: (
    'green':        $green,
    'accent':       $accent,
    'grey':         $grey,
    'grey-mid':     $grey-mid,
);

// Save as array
$theme-colors: map.merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');

$grid-columns:          12;
$grid-gutter-width:     3rem;

// Grid System
$container-max-widths: (
    sm: 475px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
);
$grid-breakpoints: (
    xs: 0,
    sm: 520px,
    md: 768px,
    lg: 1024px,
    xl: 1216px,
    xxl: 1513px
);

// Spacings
$spacer: 0.25rem;
$spacers: (
    0:  0,
    1:  $spacer * 1,
    2:  $spacer * 2,
    3:  $spacer * 3,
    4:  $spacer * 4,
    5:  $spacer * 5,
    6:  $spacer * 6,
    7:  $spacer * 7,
    8:  $spacer * 8,
    9:  $spacer * 9,
    10: $spacer * 10,
    11: $spacer * 11,
    12: $spacer * 12,
    14: $spacer * 14,
    16: $spacer * 16,
    20: $spacer * 20,
    24: $spacer * 24,
    26: $spacer * 26,
    28: $spacer * 28,
    32: $spacer * 32,
    36: $spacer * 36,
    40: $spacer * 40,
    44: $spacer * 44,
    48: $spacer * 48,
    52: $spacer * 52,
    56: $spacer * 56,
    60: $spacer * 60,
);

// border-widths
$border-width: 1px;
$border-widths: (
    1: 1px,
    2: 2px,
    3: 3px,
    4: 4px,
    5: 5px,
    6: 6px,
    7: 7px,
    8: 8px,
    9: 9px,
    10: 10px,
    15: 15px,
    20: 20px,
);

// Fix spacings
$blockquote-margin-y:       map.get($spacers, 4);
$hr-margin-y:               map.get($spacers, 4);
$card-spacer-y:             map.get($spacers, 4);
$card-spacer-x:             map.get($spacers, 4);
$card-img-overlay-padding:  map.get($spacers, 4);
$popover-header-padding-x:  map.get($spacers, 4);
$popover-body-padding-y:    map.get($spacers, 4);
$popover-body-padding-x:    map.get($spacers, 4);
$modal-inner-padding:       map.get($spacers, 4);
$alert-padding-y:           map.get($spacers, 4);
$alert-padding-x:           map.get($spacers, 4);
$list-group-item-padding-y: map.get($spacers, 2);
$list-group-item-padding-x: map.get($spacers, 4);

// Font sizes (Reset). Use "fs-X" font-size-class to set font size!
$font-size-base:              1rem;
$line-height-base:            1.4;
$font-family-base:            'GT America', sans-serif;

$headings-font-family:        'GT Alpina', serif;
$headings-font-weight:        700;
$headings-line-height:        1;
$headings-margin-bottom:      $spacer * .5;

$lead-font-size:              $font-size-base * 1.5;
$lead-font-weight:            300;

$h1-font-size: $font-size-base * 3.5;
$h2-font-size: $font-size-base * 3;
$h3-font-size: $font-size-base * 2.5;
$h4-font-size: $font-size-base * 2;
$h5-font-size: $font-size-base * 1.5;
$h6-font-size: $font-size-base * 1;

//DISPLAY FONT SIZES
$display-font-sizes: (
    1: 7rem,
    2: 6.5rem,
    3: 5.5rem,
    4: 4.5rem,
    5: 3.5rem,
    6: 2.5rem
);

// General settings
$enable-rounded:                        true;
$enable-negative-margins:               true;
//$link-color:                            $green;
$link-color:                            $black;
//$link-hover-color:                      $green;

// Buttons
$btn-border-radius:                     2rem;
$btn-padding-y:                         0.75rem;
$btn-padding-x:                         2rem;
$btn-font-size:                         0.8rem;
$btn-font-weight:                       700;
$btn-border-width:                      1px;
//$btn-box-shadow:                        inset 0 1px 5px rgba(0, 0, 0, .075);
$btn-focus-width:                       1px;
//$btn-focus-box-shadow:                  inset 0 3px 5px rgba(0, 0, 0, .075);
//$btn-active-box-shadow:                 inset 0 3px 5px rgba(0, 0, 0, .2);

$btn-link-color:                        $green;
$btn-link-hover-color:                  shade-color($green, 20%);
$btn-link-disabled-color:               $grey-mid;

//$btn-hover-bg-shade-amount:           60%;
//$btn-hover-border-shade-amount:       75%;
//$btn-active-bg-shade-amount:          60%;
//$btn-active-border-shade-amount:      75%;
//$btn-hover-bg-tint-amount:            30%;
//$btn-hover-border-tint-amount:        30%;
//$btn-active-bg-tint-amount:           45%;
//$btn-active-border-tint-amount:       45%;

// Forms
$input-padding-y:                       0.75rem;
$input-padding-x:                       1.25rem;
$input-btn-focus-width:                 .25rem;
$input-btn-focus-color-opacity:         .25;
$input-focus-border-color:              rgba($green, 1);
$input-btn-focus-blur:                  0;
//$input-btn-focus-box-shadow:          0 0 $input-btn-focus-blur $input-btn-focus-width rgba($green, .25);
//$form-select-focus-box-shadow:        0 0 $input-btn-focus-blur $input-btn-focus-width rgba($green, .25);
$input-btn-focus-box-shadow:            'none';
$input-btn-border-width:                $border-width;
$input-border-radius:                   0;
$input-border-radius-sm:                0;
$input-border-radius-lg:                0;
$input-bg:                              transparent;
$form-label-font-style:                 uppercase;
$form-feedback-icon-valid:              url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='9' cy='9' r='8.75' fill='#198754'/><path d='M5.25 9L7.75 11.5L12.75 6.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>");


$form-select-focus-box-shadow:          'none';
$input-border-radius:                   10px;
$form-select-border-radius:             10px;

$form-check-input-checked-color:        $black;
$form-check-input-checked-bg-color:     $accent;

// Badge
$badge-font-size:                       1em;
$badge-padding-y:                       .45em;
$badge-padding-x:                       1.25em;
$badge-border-radius:                   1rem;

// Dropdown
$dropdown-border-radius:                0.75rem;
$caret-width:                           0;
$dropdown-item-padding-y:               map.get($spacers, 1);
$dropdown-item-padding-x:               map.get($spacers, 4);
$dropdown-link-active-color:            $white;
$dropdown-link-active-bg:               $green;

// Modal
$modal-backdrop-opacity:                .75;

// Offcanvas
$offcanvas-horizontal-width:            100%;
$offcanvas-vertical-height:             100vh;

// List Group
$list-group-border-color:               shade-color($light, 15%);

// Accordion
//$accordion-button-icon:         svg.svg-url("<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'><path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/></svg>");
//$accordion-button-active-icon:  svg.svg-url("<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'><path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/></svg>");
