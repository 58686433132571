.component-video-gallery aside {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}
.component-video-gallery .video-container .video-item video,
.component-video-gallery .video-container .video-item iframe {
    width: 100% !important;
    max-height: 100%;
}
.component-video-gallery .video-container .video-item video:hover {
    cursor: pointer;
}

/* .component-video-gallery aside .video-item svg */
.component-video-gallery .video-container .video-item .placeholder-image,
.component-video-gallery aside .video-item .video-element {
    display: none !important;
}

.component-video-gallery aside .video-item {
    border-top: 1px solid #ccc;
    padding-top: 1.25rem;

    &:last-child {
        border-bottom: 1px solid #ccc;
        padding-bottom: 1.25rem;
    }
}

.component-video-gallery .video-container .video-item-inner {
    flex-direction: column;
}
.component-video-gallery aside .video-item-inner {
    flex-direction: row;
}
.plyr--full-ui.plyr--video .plyr__control--overlaid {
    @include media-breakpoint-up(md) {
        padding: calc(var(--plyr-control-spacing,10px) * 2.5);

        svg {
            width: 1.5em;
            height: 1.5em;
        }
    }
}
