.btn {
    @include media-breakpoint-down(md) {
        padding: 0.5rem 1.25rem;
    }

    &.btn-outline-black {
        border-color: rgba(#000, 0.2);
        transition: border-color .15s ease-in-out;

        &:hover {
            color: #000;
            border-color: rgba(#000, 0.54);
            background-color: transparent;
        }

        &.open {
            background-color: #dbdbdb;
        }
    }

    &.btn-accent {
        border: 1px solid #20CF90;
        box-shadow: 0px 32px 32px rgba(17, 106, 74, 0.08), 0px 16px 16px rgba(17, 106, 74, 0.08), 0px 4px 4px rgba(17, 106, 74, 0.08), 0px 2px 2px rgba(17, 106, 74, 0.08);
    }
}

#contactButton {
    padding: 0.75rem 1rem;
}

// close contactButton
.contact-close-btn {
    width: 40px;
    height: 40px;
}
