.newsletter-cta,
.newsletter-signup {
    input[type="email"] {
        border-bottom: 2px solid rgba($grey-mid, 0.16);

        &:focus,
        &:active {
            color: $black;
            border-bottom: 2px solid $black;
        }

        &::placeholder {
            color: rgba(0, 0, 0, 0.48) !important;
        }
    }
}
