// text selection color
::-moz-selection {
    color: var(--bs-white);
    background: var(--bs-green);
}
::selection {
    color: var(--bs-white);
    background: var(--bs-green);
}

// scrollbar styling
* {
    scrollbar-color: var(--bs-grey-light) #cccccc;
    &::-webkit-scrollbar { width: 6px; height: 6px; }
    &::-webkit-scrollbar-thumb { background: #cccccc; }
    &::-webkit-scrollbar-track { background: var(--bs-grey-light); }

    scrollbar-width: thin; //Firefox
    scrollbar-color: #cccccc;
}

// always show scrollbar
body {
    overflow-y: scroll;
}

.bg-noise {
    background-image: url('../../../web/img/noise-pattern.png');
    background-repeat: repeat;
    background-size: 200px 200px;
}

.inset-0 {
    inset: 0;
}

// backdrop blur
.backdrop {
    z-index: 2
}
body.contact-open {
    .backdrop {
        background-color: rgba(255, 255, 255, 0.63);;
    }
}
.backdrop-filter-blur-5 {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}
.backdrop-filter-blur-10 {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

// text links / underline effect
.text-link,
p > a {
    text-decoration: none;
    border-bottom: 1px solid rgba($green,0.25);
    position: relative;

    &:before {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 0;
        height: 1px;
        background-color: $green;
        visibility: hidden;
        transition: all 0.25s cubic-bezier(0, 0.7, 0.58, 1);
    }

    &:hover {
        &:before {
            visibility: visible;
            width: 100%;
        }
    }
}
.bg-green {
    .text-link,
    p > a {
        border-bottom: 1px solid rgba($white, 0.25);

        &:before {
            background-color: var(--bs-white);
        }
    }
}

// button overrides
button,
.btn {
    text-transform: uppercase;
    box-shadow: 0 6px 6px rgba(#116A4A14, 0.25);

    &:hover {
        box-shadow: none;
    }

    &.btn-link {
        font-weight: 700;
        text-decoration: none;
        letter-spacing: 0.01em;
        color: $black;
        box-shadow: none;
    }

    &[class*="outline"] {
        box-shadow: none;
    }
}

.filter-none {
    filter: none !important;
}

.list-group-flush {
    .list-group-item {
        &:last-child {
            border-bottom-width: 1px;
        }
    }
}

// navigation scroll fixed observer
.scroll-observe {
    position: absolute;
    width: 1px;
    height: 1px;
    left: 0;
}

.border-box {
    box-sizing: border-box;
}

.outline-0 {
    outline: 0;
}

.bg-alpha-grey {
    background: rgba(shade-color($grey, 10%), 0.16);
}
.bg-alpha-accent {
    background: rgba(shade-color($accent, 10%), 0.4);
}

// cookies accordion style
.accordion .open-icon { display: none; }
.accordion .close-icon { display: block; }
.accordion [aria-expanded='true'] .open-icon { display: block; }
.accordion [aria-expanded='true'] .close-icon { display: none; }
.accordion-flush .accordion-item {
    border-bottom: 1px solid rgba($grey-mid, 0.25);
    &:last-child {
        border-bottom: 1px solid rgba($grey-mid, 0.25);
    }
}

.swiper-slide {
    width: 220px;

    @include media-breakpoint-up(md) {
        width: 320px;
    }
}

//search loading indication
#indicator .loader {
    display: none;
}
#indicator.htmx-request .loader {
    display: inline;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
#indicator.htmx-request .complete {
    display: none;
}

#searchInput {
    caret-color: var(--bs-accent);

    &::placeholder { color: rgba($black, 0.3); opacity: 1; }

    &::-ms-input-placeholder { color: rgba($black, 0.3); }
}

// filter block widths
.filter-block {
    width: 14rem;
    z-index: 1;
}
.filter-block-mid {
    width: 20rem;
    z-index: 1;
}
.filter-block-max {
    width: 40rem;
    z-index: 1;
}

.pointer-events-none {
    pointer-events: none;
}
.pointer-events-auto {
    pointer-events: auto;
}

.h-fit-content {
    height: fit-content;
}

.btn-viewmode {
    &.active {
        background-color: $accent;
        border: 1px solid darken($accent, 5%);
    }
}

/*
.card-view-element {
    @include media-breakpoint-down(md) {
        border-bottom: 1px solid darken($light, 10%);
    }
}
*/

.alphabet-sorting {
    @extend .column-count-3;
    @extend .column-gap-1;
    
    * {
        hyphens: auto;
    }
}

.hyphens-auto {
    hyphens: auto;
}

.column-count-3 {
    column-count: 3;
    -webkit-column-count: 3;
    -moz-column-count: 3;

    * {
        break-inside: avoid;
    }

    @include media-breakpoint-down(md) {
        column-count: 1;
        -webkit-column-count: 1;
        -moz-column-count: 1;
    }
}
.column-gap-1 {
    column-gap: $spacer * 6;
    -webkit-column-gap: $spacer * 6;
    -moz-column-gap: $spacer * 6;

    @include media-breakpoint-down(md) {
        column-gap: 0;
        -webkit-column-gap: 0;
        -moz-column-gap: 0;
    }
}

// object-fit utility-classes
.object-fit-cover {
    object-fit: cover;
}
.object-fit-contain {
    object-fit: contain;
}
.object-fit-none {
    object-fit: none;
}
.object-fit-fill {
    object-fit: fill;
}

.vertical-scrollelement {
    margin-right: 2rem;

    -ms-overflow-style: none; // IE + Edge
    scrollbar-width: none; // Firefox

    &::-webkit-scrollbar {
        display: none;
    }
}

.context-buttons {
    -ms-overflow-style: none; // IE + Edge
    scrollbar-width: none; // Firefox

    &::-webkit-scrollbar {
        display: none;
    }

    button {
        position: relative;
        border: none;
        color: $grey;
        padding: 0.5rem 0;

        &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
            width: 100%;
            height: 1px;
            background-color: transparent;
        }

        &:last-of-type {
            margin-right: 1rem;
        }

        &.active {
            color: $black;

            &:after {
                background-color: $black;
            }
        }
    }
}

hr {
    background-color: lighten($grey-mid, 17%);
}

video[poster] {
    object-fit: cover;
}

.language-switcher {
    border: 1px solid #e1e1e1 !important;

    .hover-icon {
        display: none;
    }

    .dropdown-item {
        &:hover {
            background-color: transparent;

            .hover-icon {
                display: block;
            }
        }
    }
}

.is-valid {
    background-position: right 0 center !important;
    box-shadow: none !important;
}

// fix firefox height issue in flickity slider
.carousel-cell {
    min-width: 312px;
    min-height: 365px;
}

// remove margin top on first items in detail description
.detail-description {
    > :first-child {
        margin-top: 0;
    }
}

// table styling for ck editor tables
figure.table {
    width: 100%;

    table {
        width: 100%;

        tr {
            border: none;

            td {
                border: none;
            }
        }
    }
}

// Alpine.js cloak
[x-cloak] {
    display: none;
}