footer.page-footer {
    .text-link {
        border-bottom: 1px solid transparent;
    }

    .newsletter-signup {
        border-bottom: 2px solid rgba(255, 255, 255, 0.24) !important;

        &:has(input:focus) {
            border-bottom: 2px solid $white;
        }

        input {
            &:focus,
            &:active {
                color: $white;
                border-bottom: 2px solid $white;
            }

            &::placeholder {
                color: rgba(255, 255, 255, 0.24) !important;
            }
        }
    }
}
