#mainNavigation {
    z-index: 99;

    @include media-breakpoint-down(lg) {
        height: 76px;
    }

    a,
    .btn {
        color: inherit;
        transition: color .15s ease-in-out;
        //transition: none;
    }

    .text-link {
        border-bottom: 1px solid transparent;
    }

    .logo-wrapper {
        display: inline-block;
        padding: 2rem;
        padding-bottom: 1.5rem;
        padding-top: 4rem;

        @include media-breakpoint-down(lg) {
            padding-top: 1.5rem;
        }
    }
    .divider-line {
        z-index: -1;
        position: absolute;
        top: 48px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $grey;
    }
    .main-nav-wrapper {
        @include media-breakpoint-down(lg) {
            min-height: 76px;
        }
    }
    .meta-navigation {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

        button,
        .btn {
            color: inherit;
            text-transform: none;

            &:after {
                width: 10px;
                height: 10px;
                content: '';
                display: inline-block;
                mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgM0w1IDdMOSAzIiBzdHJva2U9ImJsYWNrIi8+Cjwvc3ZnPgo=");
                mask-size: cover;
                -webkit-mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgM0w1IDdMOSAzIiBzdHJva2U9ImJsYWNrIi8+Cjwvc3ZnPgo=");
                -webkit-mask-size: cover;
                background-color: $green;
            }

            &[aria-expanded="true"] {
                &:after {
                    transform: rotate(180deg);
                }
            }

        }
    }

    .main-nav-wrapper,
    .meta-navigation {
        a {
            &.active {
                border-bottom: 1px solid $green;
            }
        }
    }
}

.logo-wrapper {
    .letter2 {
        opacity: 0;
        transform: translateY(100%);
    }
    &.animated {
        .letter,
        .letter2 {
            transition: all .3s ease-in-out;
        }
        &:hover {
            svg {
                .letter { transform: translateY(-100%); }
                .letter2 { transform: translateY(0); opacity: 1; }
            }
        }
    }
}

body.navigation-fixed {
    padding-top: 120px; //prevent layout shifting and set height of fixed navigation as padding top

    @include media-breakpoint-down(lg) {
        padding-top: 76px;
    }

    #mainNavigation {
        z-index: 99;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: $green;
        height: 76px;
        box-shadow: 0 .5rem 1rem rgba($black, .15);

        .logo-wrapper {
            //transition: padding-left 0.5s ease-in-out;
            //transition-delay: 0.2s;

            padding-top: 1.25rem;
            padding-left: 0;
        }
        .divider-line {
            background-color: transparent;
            height: 0;
        }
        .main-nav-wrapper {
            padding-top: 0;
            height: 100%;
            justify-content: center;
            color: $white;
            margin-top: 0 !important;
        }
        nav.navigation {
            a {
                color: inherit;
            }
        }
        .meta-navigation {
            margin-top: 0;
            margin-bottom: 0;
            height: 0;
            opacity: 0;
        }

        .main-nav-wrapper,
        .meta-navigation {
            a {
                &.active {
                    border-color: $white;

                    &:hover {
                        border-bottom: 1px solid $white;

                        &:before {
                            display: none;
                        }
                    }
                }
                &:hover {
                    &:before {
                        background-color: $white;
                    }
                }
            }
        }

        .btn {
            color: $white;
        }
    }
}
#mobileNavContent {
    opacity: 0;
    pointer-events: none;
    z-index: 90;
    overflow-y: scroll;

    &.open {
        margin-top: 78px; //navigation height
        opacity: 1;
        pointer-events: auto;
    }
}

// mobile nav toggle icon show / show
.btn-navtoggle {
    .open { display: inline-block; }
    .close { display: none; }
}

body.nav-open {
    padding-right: 6px; //prevent layout shifting

    &.navigation-fixed {
        #mainNavigation {
            padding-right: 6px; //prevent layout shifting
        }
    }

    .btn-navtoggle {
        .open { display: none; }
        .close { display: inline-block; }
    }
    .btn-search {
        opacity: 0;
        pointer-events: none;
    }

    #contactButton {
        margin-right: 6px; //scrollbar width
    }
}
body.search-open {
    padding-right: 6px; //prevent layout shifting

    &.navigation-fixed {
        #mainNavigation {
            padding-right: 6px; //prevent layout shifting
        }
    }

    @include media-breakpoint-down(lg) {
        .logo-wrapper-column {
            display: none !important;
        }
        .navigation-wrapper-column {
            width: 100% !important;

            .btn-search-open {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    #mainNavigation {
        background-color: $green !important;
        color: $white;

        .divider-line {
            opacity: 0;
        }
        .navigation {
            opacity: 0 !important;
            pointer-events: none;
        }

        .meta-navigation {
            a,
            button,
            .btn {
                color: $white;

                &:before,
                &:after {
                    background-color: $white;
                }
            }

            .language-switcher {
                a {
                    color: $black;
                }
            }
        }

        .btn-search {
            .open { display: none; }
            .close { display: inline-block; }
        }
        .btn-navtoggle {
            display: none;
        }

        @include media-breakpoint-down(lg) {
            .logo-wrapper {
                display: none;
            }
        }
    }
    .backdrop {
        display: block !important;
    }

    #contactButton {
        margin-right: 6px; //scrollbar width
    }
}
#navSearchOverlay {
    @include media-breakpoint-up(lg) {
        margin-top: 3.15rem;
    }

    input {
        color: $white;
        background-color: transparent;

        &::-moz-selection { color: $green; background-color: $white; }
        &::selection { color: $green; background-color: $white; }
    }
}
body.navigation-fixed {
    #navSearchOverlay {
        margin-top: 0;
        background-color: $green;
        color: $white;
    }
}

.search-suggestion {
    span {
        padding-left: 0;
        transition: padding .25s ease-in-out;
    }

    &:hover {
        background-color: $light;

        span {
            padding-left: $spacer * 2;
        }
    }
}
